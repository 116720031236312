import React, { Component } from 'react';
import ResponsiveBox, {
    Row,
    Col,
    Item,
    Location
} from 'devextreme-react/responsive-box';

import './compatiblebrowsermessage.scss';
import chromelogo from "../../media/browser_chrome.png"
import safarilogo from "../../media/browser_safari.png"
import firefoxlogo from "../../media/browser_firefox.png"
import newedgelogo from "../../media/browser_newedge.png"
import edgelogo from "../../media/browser_edge.png"

/*
function screen(width) {
    return (width < 700) ? 'sm' : 'lg';
}*/

class compatiblebrowsermessage extends Component {
    /*constructor(props) {
        super(props);
    } */
    render() {
        return (
            <ResponsiveBox>
                <Row ratio={1} />
                <Row ratio={1} />
                <Row ratio={1} screen="xs sm"  />
                <Row ratio={1} screen="xs sm" />
                <Row ratio={1} screen="xs sm" />
                <Row ratio={1} screen="xs sm" />
                <Row ratio={1}/>

                <Col ratio={0.25} />
                <Col ratio={0.25} />
                <Col ratio={0.25} />
                <Col ratio={0.25} />
                <Item>
                    <Location screen="xs sm md lg" row={0} col={0} colspan={4} />
                    <div className="cbPanel">
                        <div className={'messageTitle'}>Please upgrade your browser to use E-Payroll Dropbox</div>
                        <div className={'messageWarningText'}>
                            We build E-Payroll Dropbox using the latest techniques and technologies. This makes 
                            E-Payroll Dropbox faster and easier to use. Unfortunately, your browser doesn't support those technologies.
                            <br /><br />
                            Make sure you're using the most recent version of your browser, or try using one of these supported browsers.
                        </div>
                    </div>
                </Item>
                <Item>
                    <Location screen="md lg" row={1} col={0} />
                    <Location screen="sm" row={2} col={0} colspan={2} />
                    <Location screen="xs" row={2} col={1} colspan={2} />
                    <div className="iconPanel">
                        <br />
                        <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                            <img src={chromelogo} alt={'Google Chrome'} className={'browserIcon'} /></a>
                        <div className={'browserName'}>
                            <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Google Chrome</a></div>
                    </div>
                </Item>
                <Item>
                    <Location screen="md lg" row={1} col={1} />
                    <Location screen="sm" row={2} col={2} colspan={2} />
                    <Location screen="xs" row={3} col={1} colspan={2} />
                    <div className="iconPanel">
                        <br/>
                        <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">
                            <img src={safarilogo} alt={'Apple Safari'} className={'browserIcon'} /></a>
                        <div className={'browserName'}>
                            <a href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">Apple Safari<br />(Mac Only)</a></div></div>
                </Item>
                <Item>
                    <Location screen="md lg" row={1} col={2} />
                    <Location screen="sm" row={3} col={0} colspan={2} />
                    <Location screen="xs" row={4} col={1} colspan={2} />
                    <div className="iconPanel">
                        <br />
                        <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
                            <img src={firefoxlogo} alt={'Mozilla Firefox'} className={'browserIcon'} /></a>
                        <div className={'browserName'}>
                            <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">Firefox</a></div></div>
                </Item>
                <Item>
                    <Location screen="md lg" row={1} col={3} />
                    <Location screen="sm" row={3} col={2} colspan={2} />
                    <Location screen="xs" row={5} col={1} colspan={2} />
                    <div className="iconPanel">
                        <br />
                        <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">
                            <img src={newedgelogo} alt={'Microsoft Edge (New)'} className={'browserIcon'} /></a>
                        <div className={'browserName'}>
                            <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">Microsoft Edge (new)</a></div></div>
                </Item>
                <Item>
                    <Location screen="md lg" row={2} col={0} colspan={4} />
                    <Location screen="xs sm" row={6} col={0} colspan={4} />
                    <div className="footerPanel">
                        <br/>
                        <hr />
                        <br />
                        Already using one of these browsers but still having
                        issues? Please email <a href="mailto:atcwebinfo@atc.sbcounty.gov">support</a>.
                    </div>
                </Item>
            </ResponsiveBox>

            )
    };
}

export default compatiblebrowsermessage;