import React, { Component } from 'react';
import axios from 'axios';
import { Button } from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import { ProgressBar } from 'devextreme-react/progress-bar';
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { LoadPanel } from 'devextreme-react/load-panel';
import { FileUploader } from 'devextreme-react/file-uploader';
import { SelectBox } from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import { TextBox } from 'devextreme-react/text-box';
import List from 'devextreme-react/list';
import './UploadFileComponent.scss';
//import UploadFileItem from './UploadFileItem'
import { initGA, GAevent } from '../../index';

import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";
require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_SPOAPI_URL + "/api";
const sharePointSite = process.env.REACT_APP_SHAREPOINTSITE;

const LoadingPos = { of: '#uploadPanel' };

// Assign variables
var variables = {
    /*
    // ATCWORLD DEV Sharepoint
    azureAD: "atcworld.onmicrosoft.com",
    // App ID: SharepointTestJS
    //clientId: "7f374d7d-005a-4762-b60a-a1552e9d2e3c",
    // App ID: EPayRollDropBoxDev
    clientId: "8306b7f2-95f8-4c2d-a1d1-c85a16ae80e2",
    // GUID of SharePoint list
    listId: "22222222-2222-2222-2222-222222222222",
    // Name of SharePoint tenant
    sharePointTenant: "atcworld",
    // Name of Sharepoint site
    sharePointSite: "wgive",
    // Name of Sharepoint document folder (not the name of Title)
    sharePointDocument: "Shared%20Documents"
    */
    // ATCGOV Production Sharepoint
    azureAD: "sbcatc.org",
    clientId: "fe58a4ce-ccfc-4ee4-bd2a-9b02b16659f7",
    listId: "22222222-2222-2222-2222-222222222222",
    sharePointTenant: "atcgov",
    //sharePointSite: "Disbursements/CentralPayroll/epaydropbox",
    //sharePointSite: "ITD/ADS/epayrolldropboxtest",
    sharePointSite: sharePointSite,
    sharePointDocument: "Shared%20Documents"
    
}

// Create config and get AuthenticationContext
window.config = {
    tenant: variables.azureAD,
    clientId: variables.clientId,
    postLogoutRedirectUri: window.location.origin,
    endpoints: {
        graphApiUri: "https://graph.microsoft.com",
        sharePointUri: "https://" + variables.sharePointTenant + ".sharepoint.com",
    },
    cacheLocation: "localStorage"
};

var
    appWebUrl = "https://" + variables.sharePointTenant + ".sharepoint.com/sites/" + variables.sharePointSite,
    hostWebUrl = "https://" + variables.sharePointTenant + ".sharepoint.com/sites/" + variables.sharePointSite;

var docType = ['Combined Giving','DE 4','Direct Deposit','Last Warrant Designation','W-4'];
var payPeriod = ['PP1', 'PP2', 'PP3', 'PP4', 'PP5', 'PP6', 'PP7', 'PP8', 'PP9', 'PP10', 'PP11', 'PP12', 'PP13', 'PP14', 'PP15', 'PP16', 'PP17', 'PP18', 'PP19', 'PP20', 'PP21', 'PP22', 'PP23', 'PP24', 'PP25', 'PP26', 'PP27'];

class UploadFileComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadIndicatorVisible: false,
            buttonText: 'Upload',
            buttonDisabled: false,
            uploaderDisabled: false,
            message: '',
            selectedFiles: [],
            arrFiles: [],
            popupVisible: false,
            popupMessage: [],
            popupTitle:'Please Correct Filename(s) and Resend',
            digestCache: '',
            progress: 0,
            allowListDelete: true,
            uploadCount: 0,
            allowExtensions: 'pdf',
            isCopyDocType: true,
            isCopyName: true,
            isCopyPayPeriod: true,
            isVisibleUploadOption: false,
            isNameLookupIndicatorVisible: false
        };

        this.onSelectedFilesChanged = this.onSelectedFilesChanged.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHidePopup = this.onHidePopup.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.onFileListOptionChanged = this.onFileListOptionChanged.bind(this);
        this.onFileListItemDeleted = this.onFileListItemDeleted.bind(this);
        this.onFileListItemDeleting = this.onFileListItemDeleting.bind(this);

        this.onDocTypeValueChanged = this.onDocTypeValueChanged.bind(this); // doctype selection
        this.onFNValueChanged = this.onFNValueChanged.bind(this);           // fn textbox
        this.onLNValueChanged = this.onLNValueChanged.bind(this);           // ln textbox
        this.onPayPeriodValueChanged = this.onPayPeriodValueChanged.bind(this); // payPeriod selection
        
        this.onCopyDocTypeChanged = this.onCopyDocTypeChanged.bind(this);   // copy checkbox 1
        this.onCopyNameChanged = this.onCopyNameChanged.bind(this);         // copy checkbox 2
        this.onCopyPayPeriodChanged = this.onCopyPayPeriodChanged.bind(this);

        this.onFileUploadContentReady = this.onFileUploadContentReady.bind(this);

        this.fileUploaderRef = React.createRef();
        this.resetFileUploader = () => {
            //console.log('Reset File Uploader is called');
            this.fileUploader.reset();
        }

        
        //this.firstNameTextBoxRef = React.createRef();

    } // constructor

    get fileUploader() {
        return this.fileUploaderRef.current.instance;
    }

    //get firstNameTextBoxInstance() {
    //    return this.firstNameTextBoxRef.current.instance;
    //}

    componentDidMount() {
        initGA();
        //debugger;
        //console.log(">> componentDidMount: " + this.props.loggedIn);
    };

    componentDidUpdate() {
        //console.log(">> componentDidUpdate: " + this.props.loggedIn);
        //debugger;
        //console.log(">> + spoInfo: " + this.props.spoInfo);
    };

    onSelectedFilesChanged(e) {
        GAevent('UploadingFiles','Files dropped event triggered')
        //debugger;
        //console.log("onSelectedFilesChanged event is raised");
        //Check each filename
        if (e.value.length > 0) {
            var chkErrStr = this.checkFilenames(e.value, this.state.allowExtensions);
            if (chkErrStr.length > 0) {
                let popupMessage = [];
                popupMessage.push(chkErrStr);
                this.setState({ popupVisible: true, popupMessage: popupMessage, popupTitle: 'Please Correct Filename(s) and Resend' });
            }
            else {
                //populate the first name and last name
                this.setState({ isNameLookupIndicatorVisible: true });
                const arrTemp = e.value.slice();;
                let myObjList = [];
                for (let i = 0; i < arrTemp.length; i++) {
                    const eid = arrTemp[i].name.split('_')[0];
                    myObjList.push({ EmployeeIdCalculated: eid });
                }
                const values = JSON.stringify(myObjList);
                const requestOption = {
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
                }
                axios.post(url + "/GetUserADInfo", values, requestOption)
                    .then(response => {
                        //debugger;
                        if (response.status === 200) {

                            for (let i = 0; i < response.data.length; i++) {
                                e.value[i].firstName = response.data[i].firstName;
                                e.value[i].lastName = response.data[i].lastName;
                            }


                            // Check if array state is empty
                            if (this.state.selectedFiles.length === 0) {
                                // Build array for file list
                                const arr = [];
                                const arrF = e.value.slice();
                                for (let i = 0; i < arrF.length; i++) {
                                    arr.push({ id: i, name: arrF[i].name, size: arrF[i].size, uploadstatus: '', progress: 0, bytes: 0, docutype: '', efn: arrF[i].firstName, eln: arrF[i].lastName, eid: arrF[i].name.split('_')[0], pPeriod: '', uploadby: '' });
                                }
                                this.setState({ selectedFiles: e.value, arrFiles: arr, progress: 0, buttonDisabled: false, buttonText: 'Upload', isVisibleUploadOption: true, isNameLookupIndicatorVisible: false });
                            } else {

                                //this.state.selectedFiles.push.apply(e.value);
                                //TODO: Check if the new dropoff has the same filename
                                const arr = [];
                                const arrF = this.state.selectedFiles.slice();
                                const arrC = arrF.concat(e.value);
                                for (let i = 0; i < arrC.length; i++) {
                                    arr.push({ id: i, name: arrC[i].name, size: arrC[i].size, uploadstatus: '', progress: 0, bytes: 0, docutype: '', efn: arrC[i].firstName, eln: arrC[i].lastName, eid: arrC[i].name.split('_')[0], pPeriod: '', uploadby: '' });
                                }
                                this.setState({ selectedFiles: arrC, arrFiles: arr, progress: 0, buttonDisabled: false, buttonText: 'Upload', isVisibleUploadOption: true, isNameLookupIndicatorVisible: false });

                            }
                        } else {
                            //throw 'error';
                            throw new Error("error");
                        }
                    });


            }
        }
        //debugger;
    }

    onFileUploadContentReady(e) {
        //console.log('File Upload Content Ready event is triggered');
    }

    onClick = args => {
        GAevent('UploadingFiles', 'Upload button is clicked')
        //debugger;
        
        //this.firstNameTextBoxInstance.focus();
        //const firstName = this.firstNameTextBoxInstance.option('value');

        const fileArray = this.state.selectedFiles;
        let arrItem = this.state.arrFiles;

        if (fileArray.length === 0) {

            this.setState({ popupVisible: true, popupMessage: ["No file selected."] });
        }
        else {
            //check files naming convention
            let popupMessage = [];
            let errorMessage = '';
            for (let i = 0; i < arrItem.length; i++) {
                if (!arrItem[i].docutype || !arrItem[i].efn || !arrItem[i].eln ) {
                    errorMessage += "<div class='errRow'><div class='fa fa-times errIcon'></div><div class='errText'>"
                        + arrItem[i].name
                        + " ( Missing "
                        + ((!arrItem[i].docutype) ? " Doc Type; " : "")
                        + ((!arrItem[i].efn) ? " First Name;  " : "")
                        + ((!arrItem[i].eln) ? " Last Name;  " : "")
                        + ((!arrItem[i].pPeriod) ? " Effective PP;  " : "")
                        + " )</div></div>";
                }
            }
            if (errorMessage !== "") {
                popupMessage.push(errorMessage);
            }

            if (popupMessage.length !== 0) {
                this.setState({ popupVisible: true, popupMessage: popupMessage, popupTitle:'Please Correct Information' });
            }
            else {
                this.setState({ loadIndicatorVisible: true, buttonText: 'Uploading', buttonDisabled: true, message: '', allowListDelete: false });

                const ls = new SecureLS();
                const userinfo = ls.get('userinfo');
                let currComponent = this;
                //debugger;
                
                const promises = [];
                (function loop(i) {
                    if (i < fileArray.length)
                        promises.push(new Promise((resolve, reject) => {
                            currComponent.setState({ loadIndicatorVisible: true, buttonText: 'Uploading', progress: 0, uploaderDisabled: true });
                            currComponent.uploadFile(fileArray[i], arrItem[i], userinfo, currComponent.props.spoInfo, currComponent.state, event => { var percentComplete = Math.round((100 * event.loaded) / event.total); arrItem[i].bytes = event.loaded; arrItem[i].progress = percentComplete; currComponent.setState({ progress: percentComplete, arrFiles: arrItem }); console.log('progress: ' + percentComplete); })
                                .then((values) => {
                                    currComponent.setState({ loadIndicatorVisible: false, buttonText: 'Upload Completed', uploaderDisabled: false, uploadCount: i + 1 })

                                    if (currComponent.state.uploadCount === fileArray.length) {
                                        console.log('all done');
                                        //debugger;
                                        
                                        currComponent.sendConfirmation(currComponent.state.arrFiles)
                                            .then((res) => {
                                                console.log('email sent');
                                                currComponent.setState({ message: 'Email confirmation has been sent to ' + res, selectedFiles:[] });
                                            });
                                    }
                                    resolve(i);
                                });
                        }).then(loop.bind(null, i + 1)))
                })(0);
            }
        }


    }

    onHidePopup() {
        this.setState({ popupVisible: false });
    }

    onProgress(e) {
        //console.log("onProgress event is raised");
    }
    onUploadStarted(e) {
        //console.log("onUploadStarted event is raised");
    }
    onUploaded(e) {
        //console.log("onUploaded event is raised");
    }
    onOptionChanged(e) {
        //debugger;
        //console.log("onOptionChanged event is raised");
    }
    onFileListOptionChanged(e) {
        //debugger;
        if (e.name === "dataSource") {
            //console.log("onFileList Option Changed event is raised");
        }
        
    }
    onFileListItemDeleted(e) {
        //debugger;
        var arr = this.state.selectedFiles;
        if (arr.length === 1) {
            this.setState({ selectedFiles: [] });
            this.resetFileUploader();
        } else {
            for (var i = arr.length - 1; i >= 0; --i) {
                if (arr[i].name === e.itemData.name) {
                    arr.splice(i, 1);
                }
            }
            this.setState({ selectedFiles: arr });
        }
        
        // if 
        //console.log("onFileList Item Deleted event is raised");
        //debugger;
    }

    onFileListItemDeleting(e) {
        //debugger;
        //console.log("onFileList Item Deleting event is raised");
    }

    onDocTypeValueChanged(e, name) {
        //console.log('on DocTypeChanged, Doc Type: ' + e.value + ', filename: ' + name);
        //TODO: Update state
        //TODO: Check copy flag
        //Loop thru affFiles
        const arrF = this.state.arrFiles.slice();
        for (var i = arrF.length - 1; i >= 0; --i) {
            if (this.state.isCopyDocType) {
                arrF[i].docutype = e.value;
            }
            else {
                if (arrF[i].name === name) {
                    arrF[i].docutype = e.value;
                    //break;
                }
            }
        }
        this.setState({ arrFiles: arrF });
        //debugger;
    }
    onFNValueChanged(e, name) {
        //debugger;
        //console.log('On FNChanged, Firstname: ' + e.value + ', filename: ' + name);
        //TODO: Update state
        //TODO: Check copy flag
        //Loop thru affFiles
        const arrF = this.state.arrFiles.slice();
        for (var i = arrF.length - 1; i >= 0; --i) {
            if (this.state.isCopyName) {
                arrF[i].efn = e.value;
            }   
            else {
                if (arrF[i].name === name) {
                    arrF[i].efn = e.value;
                    //break;
                }
            }
            
        }
        this.setState({ arrFiles: arrF });
        //debugger;
    }
    onLNValueChanged(e, name) {
        //console.log('On LNChanged, Lastname: ' + e.value + ', filename: ' + name);
        //TODO: Update state
        //TODO: Check copy flag
        //Loop thru affFiles
        const arrF = this.state.arrFiles.slice();
        console.log('> 1');
        for (var i = arrF.length - 1; i >= 0; --i) {
            //console.log('> 1-'+i);
            if (this.state.isCopyName) {
                //console.log('> 1-' + i + 'a');
                arrF[i].eln = e.value;
            } else {
                //console.log('> 1-' + i + 'b');
                if (arrF[i].name === name) {
                    //console.log('> 1-' + i + 'bA');
                    arrF[i].eln = e.value;
                    //break;
                }
            }
            
        }
        this.setState({ arrFiles: arrF });
        //console.log('> 2');
        //debugger;
    }
    onPayPeriodValueChanged(e, name) {
        //console.log('on PayPeriodChanged, Pay Period: ' + e.value);
        //TODO: Update state
        //TODO: Check copy flag
        //Loop thru affFiles
        let pPeriod = "";
        if (e.value == null) { }
        else {
            pPeriod = e.value;
        }
        const arrF = this.state.arrFiles.slice();
        for (var i = arrF.length - 1; i >= 0; --i) {
            if (this.state.isCopyPayPeriod) {
                arrF[i].pPeriod = pPeriod;
            }
            else {
                if (arrF[i].name === name) {
                    arrF[i].pPeriod = pPeriod;
                    //break;
                }
            }
        }
        this.setState({ arrFiles: arrF });
        //debugger;
    }
    onCopyDocTypeChanged(e) {
        //debugger;
        //console.log('Copy Doc Type is changed');
        this.setState({ isCopyDocType: e.value });
    }
    onCopyNameChanged(e) {
        //console.log('Copy Name is changed');
        this.setState({ isCopyName: e.value });
    }
    onCopyPayPeriodChanged(e) {
        //console.log('Copy Pay Period is changed');
        this.setState({ isCopyPayPeriod: e.value });
    }

    render() {
        const { loadIndicatorVisible, isNameLookupIndicatorVisible, buttonText, buttonDisabled, uploaderDisabled, message, popupVisible, popupMessage, popupTitle, /*digestCache, progress, selectedFiles,*/ arrFiles, allowListDelete, /*uploadCount, allowExtensions,*/ isCopyDocType, isCopyName, isCopyPayPeriod, isVisibleUploadOption /*, fnEntry*/ } = this.state;
        /*const { loggedIn } = this.props;*/
        const fileItem = (item) => {
            //debugger;
            //console.log('render form start');
            return (
                
                <div className={'fileItem'}>
                    <div className={'fileName'}>{item.name}  <span className={'fileSize'}>{(Math.round(item.size / 1000)).toLocaleString() + ' KB'}</span></div>
                    <div className={'docTypePanel'}>
                        <div className={'docTypeContainer'}>
                            <div className={'docTypeText'}>DOC TYPE:</div>
                            <SelectBox className={'docTypeSelectBox'} items={docType} onValueChanged={(e) => { this.onDocTypeValueChanged(e, item.name) }} showClearButton={true} clientId={item.id} stylingMode={"outlined"}
                                defaultValue={item.docutype} dropDownOptions={{ minWidth: '220px' }}
                            ></SelectBox>
                        </div>
                        <div className={'fnContainer'}>
                            <div className={'docTypeTextFn'}>FIRSTNAME:</div>
                            <TextBox className={'firstNameTextBox'}
                                placeholder={'Enter Firstname'}
                                stylingMode={"outlined"}
                                onValueChanged={(e) => { this.onFNValueChanged(e, item.name) }}
                                showClearButton={true}
                                defaultValue={item.efn}
                            //ref={this.firstNameTextBoxRef}
                            >
                            </TextBox>
                        </div>

                        <div className={'lnContainer'}>
                            <div className={'docTypeTextLn'}>LASTNAME:</div>
                            <TextBox className={'lastNameTextBox'} placeholder={'Enter Lastname'} stylingMode={"outlined"} onValueChanged={(e) => { this.onLNValueChanged(e, item.name) }} showClearButton={true}
                                defaultValue={item.eln}
                            />
                        </div>

                        <div className={'payPeriodContainer'}>
                            <div className={'payPeriodText'}>Effective PP:</div>
                            <SelectBox className={'payPeriodSelectBox'} items={payPeriod} onValueChanged={(e) => { this.onPayPeriodValueChanged(e, item.name) }} showClearButton={true} clientId={item.id} stylingMode={"outlined"}
                                defaultValue={item.pPeriod} dropDownOptions={{ minWidth: '220px' }}
                            ></SelectBox>
                        </div>

                    </div>
                    <ProgressBar
                        id="progress-bar-status"
                        className={item.progress === 0 ? '' : 'complete'}
                        width="100%"
                        min={0}
                        max={100}
                        value={item.progress}
                        statusFormat={function (ratio, value) {
                            return ratio === 0 ? 'Ready to upload' :
                                ratio > 0 && ratio < 0.01 ? 'Uploading ...' :
                                    ratio >= 0.01 && ratio < 0.99 ? 'Progress: ' + Math.round(ratio * 100) + '% (' + (item.bytes / 1000).toLocaleString() + ' KB)' :
                                        ratio >= 0.99 && ratio < 1 ? 'Almost Done ...' : 'Completed'
                        }}
                    />
                    <div>{item.uploadstatus}</div>
                </div>
            );
            
        }
        return (
            <div className={'component'}>
                <LoadPanel
                    shadingColor={'rgba(0,0,0,0.4)'}
                    position={LoadingPos}
                    visible={isNameLookupIndicatorVisible}
                    showIndicator={true}
                    message={"Please wait.."}
                    shading={true}
                    showPane={true}
                />
                <div id={'dropzone'}>
                    <FileUploader
                        selectButtonText="Click to Select Files"
                        labelText="Or Drop Files Here"
                        multiple={true}
                        disabled={uploaderDisabled}
                        //accept={'*'}
                        allowedFileExtensions={['.xlsx', '.pdf']}
                        allowCanceling={true}
                        uploadMode={'useForm'}
                        readyToUploadMessage={''}
                        showFileList={false}
                        onValueChanged={this.onSelectedFilesChanged}
                        onOptionChanged={this.onOptionChanged}
                        onProgress={this.onProgress}
                        onUploadStarted={this.onUploadStarted}
                        onUploaded={this.onUploaded}
                        onContentReady={this.onFileUploadContentReady} ref={this.fileUploaderRef}
                        />
                </div>
                <div className={'uploadOptions'}>
                    <CheckBox
                        text="COPY DOCUMENT TYPE"
                        className={'chkDocType'}
                        defaultValue={isCopyDocType}
                        onValueChanged={this.onCopyDocTypeChanged}
                        visible={isVisibleUploadOption}
                    />
                    <CheckBox
                        text="COPY NAME"
                        className={'chkName'}
                        defaultValue={isCopyName}
                        onValueChanged={this.onCopyNameChanged}
                        visible={isVisibleUploadOption}
                    />
                    <CheckBox
                        text="COPY Effective PP"
                        className={'chkPayPeriod'}
                        defaultValue={isCopyPayPeriod}
                        onValueChanged={this.onCopyPayPeriodChanged}
                        visible={isVisibleUploadOption}
                    />
                </div>
                <List
                    className={'fileList'}
                    dataSource={arrFiles}
                    itemRender={fileItem}
                    allowItemDeleting={allowListDelete}
                    itemDeleteMode={'static'}
                    noDataText={''}
                    showSelectionControls={false}
                    onOptionChanged={this.onFileListOptionChanged}
                    onItemDeleted={this.onFileListItemDeleted}
                    onItemDeleting={this.onFileListItemDeleting}
                    >
                    </List>
                
                <ProgressBar
                    id="progress-bar-status"
                    className={this.state.progress === 0 ? '' : 'complete'}
                    width="100%"
                    min={0}
                    max={100}
                    value={this.state.progress}
                    visible={false}
                />
                <br />
                <br />
                <div className={'message'}>{message}</div>
                <Button width={180} height={40} type='default' disabled={buttonDisabled} onClick={this.onClick}> 
                    <LoadIndicator className={'button-indicator'} visible={loadIndicatorVisible} />
                    <span className="dx-button-text">{buttonText}</span>
                </Button>
                

                <Popup
                    visible={popupVisible}
                    onHiding={this.onHidePopup}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title={popupTitle}
                    minheight={250}
                    height={function() { return window.innerHeight / 2; }}
                    maxWidth={600}
                    contentTemplate={function () { return popupMessage[0] }}
                ></Popup>
            </div>
        );
    }

    uploadFile(arrayBuffer, arrayItem, userInfo, token, state, onUploadProgress) {

        // Get test values from the file input and text input page controls.
        // The display name must be unique every time you run the example.
        //debugger;
        var fileName = arrayBuffer.name.replace('.' + getFileExtension(arrayBuffer.name), '') + "_" + getFormattedTime() + '.' + getFileExtension(arrayBuffer.name);

        return getFileBuffer(arrayBuffer, state)
            .then(function (arrayBuffer) {
                //console.log("getFileBuffer completed");
                return getRequestDigest(appWebUrl, token)
                    .then(function (digestCache) {
                        //debugger;
                        //return addFileToFolder(arrayBuffer, token, fileName, digestCache, state, event => { state.progress = Math.round((100 * event.loaded) / event.total); console.log('progress: ' + state.progress); })
                        return addFileToFolder(arrayBuffer, token, fileName, digestCache, state, onUploadProgress)
                            .then(function (values) {
                                //console.log("addFileToFolder completed");
                                //return values;
                                return getListItem(values.d.ListItemAllFields.__deferred.uri, token, state)
                                    .then(function (values) {
                                        //console.log("getListItem completed");
                                        //debugger;
                                        return updateListItem(values.d.__metadata, token, digestCache, arrayItem, userInfo)
                                            .then(function (values) {
                                                //console.log("updateListItem completed");
                                                //debugger;
                                            });
                                    });
                            });
                    });

            }); 

        // Get the local file as an array buffer.
        function getFileBuffer(fileArray) {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onloadend = function (e) {
                    resolve(e.target.result);
                }
                reader.onerror = function (e) {
                    reject(e.target.error)
                }
                reader.readAsArrayBuffer(fileArray);
            });
        } // getFileBuffer

        // Add the file to the file collection in the Shared Documents folder.
        function addFileToFolder(arrayBuffer, token, fileName, digestCache, state, onUploadProgress) {
            // Define the folder path for this example.
            var serverRelativeUrlToFolder = '/sites/' + variables.sharePointSite + '/' + variables.sharePointDocument;
            // Get the file name from the file input control on the page.

            // Construct the endpoint.
            var fileCollectionEndpoint = String.format(
                "{0}/_api/sp.appcontextsite(@target)/web/getfolderbyserverrelativeurl('{1}')/files" +
                "/add(overwrite=true,%20url='{2}')?@target='{3}'",
                appWebUrl, serverRelativeUrlToFolder, fileName, hostWebUrl);

            var options = {
                onUploadProgress,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "accept": "application/json;odata=verbose",
                    "X-RequestDigest": digestCache,
                    "Content-Type": "application/json;odata=minimalmetadata;streaming=true;charset=utf-8"
                }
            }

            //debugger;
            return axios.post(fileCollectionEndpoint, arrayBuffer, options)
                .then(response => {
                    //debugger;
                    if (response.status === 200) {
                        console.log("Successfully upload file to SharePoint.");
                        return response.data;
                    } else {
                        //console.log("Upload to SharePoint failed.");
                    }
                })
        } // addFileToFolder

        function getRequestDigest(target, token) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            }
            const bodyParameters = {
                key: "value"
            };
            return axios.post(target + "/_api/contextinfo", bodyParameters, config)
                .then(response => {
                    if (response.status === 200) {
                        //console.log("Successfully getting Request Digest: ");

                        //state.setState({ digestCache: response.data.FormDigestValue });
                        return response.data.FormDigestValue;
                    } else {
                        //console.log("Failed getting Request Digest");
                        //throw 'error';
                        throw new Error("error");
                    }
                })
        } //getRequestDigest

        function getListItem(fileListItemUri, token, state) {
            fileListItemUri = fileListItemUri.replace(hostWebUrl, '{0}');
            fileListItemUri = fileListItemUri.replace('_api/Web', '_api/web');
            var listItemAllFieldsEndpoint = String.format(fileListItemUri + "", appWebUrl);

            var options = {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "accept": "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=minimalmetadata;streaming=true;charset=utf-8"
                }
            }

            return axios.get(listItemAllFieldsEndpoint, options)
                .then(response => {
                    //debugger;
                    if (response.status === 200) {
                        //console.log("Successfully get an item list from SharePoint.");
                        return response.data;
                    } else {
                        //console.log("Get an item list from SharePoint failed.");
                        //throw 'error';
                        throw new Error("error");
                    }
                })
        } // getListItem

        function updateListItem(itemMetadata, token, digestCache, arrayItem, userInfo) {
            var listItemUri = itemMetadata.uri.replace('_api/Web', '_api/sp.appcontextsite(@target)/web');
            var listItemEndpoint = String.format(listItemUri + "?@target='{0}'", hostWebUrl);

            var body = String.format("{'__metadata':{'type':'{0}'},'Uploaded_x0020_By':'{1}','Employee_x0020_ID':'{2}','Employee_x0020_name':'{3}','Document_x0020_Type':'{4}','Effective_x0020_PP':'{5}'}",
                itemMetadata.type, userInfo.email, arrayItem.eid, arrayItem.efn + ' ' + arrayItem.eln, arrayItem.docutype, arrayItem.pPeriod);

            var options = {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "accept": "application/json;odata=verbose",
                    "X-RequestDigest": digestCache,
                    "IF-MATCH": itemMetadata.etag,
                    "X-HTTP-Method": "MERGE",
                    "Content-Type": "application/json;odata=verbose"
                }
            }

            return axios.post(listItemEndpoint, body, options)
                .then(response => {
                    //debugger;
                    // 204 No Content with ETag in header
                    if (response.status === 204) {
                        console.log("Successfully update item list on SharePoint.");
                        return response.data;
                    } else {
                        //console.log("Update item list on SharePoint failed.");
                        //throw 'error';
                        throw new Error("error");
                    }
                })
        } // updateListItem
    }

    

    sendConfirmation(arrayBuffer) {
        const ls = new SecureLS();
        const userInfo = ls.get('userinfo');
        // Compose Email
        var FilesUpload = {
            "Department": userInfo.department,
            "Email": userInfo.email
        };
        FilesUpload.Files = []
        for (var i = 0; i < arrayBuffer.length; i++) {
            FilesUpload.Files.push({ Filename: arrayBuffer[i].name, Size: (Math.round(arrayBuffer[i].size / 1000)).toLocaleString() + ' KB' });
            
        }
        const values = JSON.stringify(FilesUpload);
        //debugger;
        // Send EMail
        const requestOption = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }
        return axios.post(url + "/SendUploadEmailConfirmation", values, requestOption)
            .then(response => {
                //debugger;
                if (response.status === 200) {
                    //console.log("Successfully sent confirmation.");
                    
                    return userInfo.email;
                } else {
                    //console.log("Failed sent confirmation.");
                    //throw 'error';
                    throw new Error("error");
                }
            })
    }

    checkFilenames(arrayBuffer, allowExtensions) {
        //debugger;
        var validFn = [], validExt = [];
        var errStr = '',  errMsg = '', corrStr = '';
        for (var i = 0; i < arrayBuffer.length; i++) {
            validFn = this.validateFilename2(arrayBuffer[i].name).split('^');
            validExt = this.validateExtension2(arrayBuffer[i].name, allowExtensions).split('^');
            errMsg = validExt[1].length === 0 ? validFn[1] : validFn[1] + ",<br/>" + validExt[1];
            if (validFn[0]==='0' || validExt[0]==='0') {
                errStr += "<div class='errRow'><div class='fa fa-times errIcon'></div><div class='errText'>" + arrayBuffer[i].name + " <span class='errMsg'>(" + errMsg + " )</span></div></div>"
            } else {
                corrStr += "<div class='corrRow'><div class='fa fa-check corrIcon'></div><div class='corrText'>" + arrayBuffer[i].name + "</div></div>"
            }
        }
        if (errStr.length > 0) {
            errStr += corrStr;
        }
        return errStr;
    }

    validateFilename(fileName) {
        // (Example: D12345_PayrollFile.PDF)
        //debugger;
        const wordArray = fileName.split('_');
        if (wordArray.length > 1) { // at least one underscore
            if (wordArray[0].length < 5) {
                return false;
            }
        }
        else {
            return false;
        }
        return true;
    }

    validateFilename2(fileName) {
        // (Example: D12345_PayrollFile.PDF)
        //debugger;
        var regexp = /^\S*$/; // no spaces
        const wordArray = fileName.split('_');
        if (wordArray.length > 1) { // at least one underscore
            if (wordArray[0].length < 5) {
                return "0^Filename must contain Employee ID";
            }
            if (!regexp.test(wordArray[0])) {
                return "0^Filename must contain Employee ID";
            }
        }
        else {
            return "0^Filename must contain underscore '_'";
        }
        return "1^";
    }

    validateExtension(fileName, allowExtensions) {
        const allowextarr = allowExtensions.split(',');
        //debugger;
        for (var i = 0; i < allowextarr.length; i++) {
            if (getFileExtension(fileName).indexOf(allowextarr[i]) !== -1) {
                return true;
            }
        }
        return false;
    }

    validateExtension2(fileName, allowExtensions) {
        const allowextarr = allowExtensions.split(',');
        //debugger;
        for (var i = 0; i < allowextarr.length; i++) {
            if (getFileExtension(fileName).indexOf(allowextarr[i]) !== -1) {
                return "1^";
            }
        }
        return "0^Only "+allowExtensions+" document file(s) only";
    }

    
}

export default UploadFileComponent;

// Display error messages.
/*
function onError(error) {
    alert(error.responseText);
}*/

function getFileExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "" + m + "" + d + "" + h + "" + mi + "" + s;
}

/*
function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
        // (supports `\\` and `/` separators)
        pos = basename.lastIndexOf(".");       // get last position of `.`

    if (basename === "" || pos < 1)            // if file name is empty or ...
        return "";                             //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1);            // extract extension ignoring `.`
}*/

String.format = function () {
    var s = arguments[0];
    for (var i = 0; i < arguments.length - 1; i++) {
        var reg = new RegExp("\\{" + i + "\\}", "gm");
        s = s.replace(reg, arguments[i + 1]);
    }

    return s;
}

Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};