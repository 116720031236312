export const util = {
    sendRequest
};

export function sendRequest(url, method, body,token) {
    //debugger;
    method = method || 'GET';

    if (method === 'GET') {
        return fetch(url, {
            method: method,
            //credentials: 'include'
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(result => {
            //debugger;
            if (result.ok) {
                return result.json();
            }
            else {
                if (result.status === 401) {
                }
                //throw "error";
            }
        });
    }
    else {
        body = body || {};

        return fetch(url, {
            method: method,
            //credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: body
        }).then(result => {
            //debugger;
            if (result.ok) {
                return result.json();
            } else {
                if (result.status === 401) {
                }
            }
        });
    }
}