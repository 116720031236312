import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, { Component } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import appInfo from './app-info';
import { navigation } from './app-navigation';
import routes from './app-routes';
import './App.scss';
import './dx-styles.scss';
import { Footer, LoginForm } from './components';
import {
  SideNavInnerToolbar as SideNavBarLayout,
  SingleCard
} from './layouts';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import SecureLS from "../node_modules/secure-ls/dist/secure-ls";
import eprlogo from "./media/epr.png"
import sbclogo from "./media/sb_cologo-full_color_300.png"
import CompatibleBrowserMessage from "./components/browserdetect/compatiblebrowsermessage.js"
import NoBotMessage from "./components/browserdetect/nobotmessage.js"
import { initGA, GApageView } from '.';
/*
const trackingId = "UA-75182977-7"; 
ReactGA.initialize(trackingId);
ReactGA.pageview('/homepage');*/


const LoginContainer = ({ logIn }) => <LoginForm onLoginClick={logIn} />;

const NotAuthPage = (props) => {
    GApageView("/loginpage");
    return (
        <React.Fragment>
            <SingleCard>
                <Route render={() => <LoginContainer {...props} />} />
                <Footer>
                    {(new Date().getFullYear())} San Bernardino County Auditor-Controller/Treasurer/Tax Collector.<br />Contact Central Payroll at (909) 382-3154
                </Footer>
            </SingleCard>
        </React.Fragment>
    );
};

const AuthPage = (props) => {
    GApageView("/uploaderpage");
    return (
        <SideNavBarLayout menuItems={navigation} title={appInfo.title} {...props}>
            <Switch>
                {routes.map(item => (
                    <Route
                        exact
                        key={item.path}
                        path={item.path}
                        component={item.component}
                    />
                ))}
                <Redirect to={'/uploadfile'} />
            </Switch>
            <Footer>
                {(new Date().getFullYear())} San Bernardino County Auditor-Controller/Treasurer/Tax Collector.<br />Contact Central Payroll at (909) 382-3154
            </Footer>
        </SideNavBarLayout>
    );
};

const { detect } = require('detect-browser');
const browser = detect();

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            screenSizeClass: this.getScreenSizeClass(),
            isBrowserOk: 0

        };

        this.userMenuItems = [
            {
                text: 'Logout',
                icon: 'fas fa-sign-out-alt',
                onClick: this.logOut
            }
        ];
    }

    componentDidMount() {
        initGA();

        this.setState({ isBrowserOk: this.isBrowserOk()});
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');
        //debugger;
        if (userinfo !== "") {
            this.setState({ loggedIn: true });
        }

        subscribe(this.screenSizeChanged);
    }

    componentWillUnmount() {
        unsubscribe(this.screenSizeChanged);
    }

    render() {
        const { loggedIn, isBrowserOk } = this.state;
        const BrowserNotOk = (props) => {
            GApageView("/browsernotok");
            return (
                <SingleCard>
                    <CompatibleBrowserMessage></CompatibleBrowserMessage>
                </SingleCard>  
            );
        }
        const BotNotWelcome = (props) => {
            GApageView("/nobot");
            return (
                <SingleCard>
                        <NoBotMessage></NoBotMessage>
                </SingleCard>
            );
        }
        return (
            <div className={`app ${this.state.screenSizeClass} `}>

                <Router>{loggedIn ? <AuthPage userMenuItems={this.userMenuItems} /> :

                    <React.Fragment>
                        <div className={'logincontainer'}>
                            <div id={'Logon_UPHeader'}>
                                <div className={'white borderBottom width100'} id={'headerTableDiv'}>
                                    <div className={'paddings sizeLimit'}>
                                        <table id={'headerTable'} className={'headerTable xafAlignCenter white width100 sizeLimit'}>
                                            <tbody>
                                                <tr>
                                                    <td valign="middle">
                                                        <a id="Logon_LogoLink" href="/">
                                                            <img src={eprlogo} alt={'ATC Dropbox'} className={'eprlogo'} /></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={'loginsection'}>
                                <img src={sbclogo} className={'sbclogo'} alt={'ATC Dropbox'} />
                            </div>
                            {isBrowserOk === 1 ?
                                <NotAuthPage logIn={this.logIn} />
                                :
                                isBrowserOk < 0 ? <BotNotWelcome /> : <BrowserNotOk />}

                        </div>
                        
                    </React.Fragment>
                }
                </Router>

            </div>
        );
    }

    isBrowserOk() {
        if (browser) {
            switch (browser.type) {
                case 'bot':
                    // result is an instanceof BotInfo
                    //console.log(`found ${browser.name} bot`);
                    return -1   
                    //break;
                case 'bot-device':
                    // result is an instanceof SearchBotDeviceInfo
                    //console.log(`found ${browser.name} device bot`);
                    return -2
                    //break;
                case 'browser':
                    // result is an instanceof BrowserInfo
                    //console.log(`found ${browser.name} browser, version ${browser.version}`);
                    switch (browser.name) {
                        case 'chrome':
                            return browser.version <= 72 ? 0 : 1;
                            //break;
                        case 'safari':
                            return browser.version <= 12 ? 0 : 1;
                            //break;
                        case 'ios':
                            return browser.version <= 12 ? 0 : 1;
                            //break;
                        case 'firefox':
                            return browser.version <= 68 ? 0 : 1;
                            //break;
                        case 'edge-chromium':
                            return browser.version <= 83 ? 0 : 1;
                            //break;
                        //case 'edge':
                            // return browser.version <= 42 ? 0 : 1;
                            // break;
                        default:
                            return 0;
                            //break;
                    }
                    //break;
                case 'node':
                    // result is an instanceof NodeInfo
                    //console.log(`found node version ${browser.version}`);
                    return -3;
                    //break;
                default:
                    return -3;
            }
        }
        return 0; // unknown agent
    }

    getScreenSizeClass() {
        const screenSizes = sizes();
        return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
    }

    screenSizeChanged = () => {
        this.setState({
            screenSizeClass: this.getScreenSizeClass()
        });
    }

    logIn = () => {
        this.setState({ loggedIn: true });
    };

    logOut = () => {
        const ls = new SecureLS();
        ls.removeAll();

        this.setState({ loggedIn: false });
    };
}

export default App;
