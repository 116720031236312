import React, { Component } from 'react';
import axios from 'axios';
import { LoadPanel } from 'devextreme-react/load-panel';
import UploadFileComponent from './UploadFileComponent.js';
import './uploadfile.scss';

import eprlogo from "../../media/epr.png"
import eprdb from "../../media/eprdb.png"

import CompatibleBrowserMessage from "../../components/browserdetect/compatiblebrowsermessage.js"
import NoBotMessage from "../../components/browserdetect/nobotmessage.js"

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_SPOAPI_URL + "/api";

const LoadingPos = { of: '#uploadPanel' };

const { detect } = require('detect-browser');
const browser = detect();

class uploadfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggingIn: true,
            loggedIn: false,
            spoInfo: '',
            isBrowserOk: 0
        };

    }

    componentDidMount() {
        //console.log("> componentDidMount: " + this.state.loggedIn);
        this.setState({ isBrowserOk: this.isBrowserOk() });
        this.checkSPOInfo();
    };

    componentDidUpdate() {
        //console.log("> componentDidUpdate: " + this.state.loggedIn);
    };

    render() {
        const { loggingIn, isBrowserOk } = this.state;
        const BrowserOk = (props) => {
            //debugger;
            return (
                <div>
                    <div className={'info'}>
                        <div className={''}>Send files in the following file naming format:</div>
                        <div className={'nametitle'}>EMPLOYEEID_FILENAME.PDF</div>
                        <div className={'nameexample'}>(Example: D1234_PayrollFile.PDF)</div>
                    </div>
                    <div className={'dx-card responsive-paddings'} id={'uploadPanel'}>
                        <UploadFileComponent {...props} />
                    </div>
                </div>
            );
        }

        const BrowserNotOk = (props) => {
            return (
                <div>
                    <div className={'dx-card responsive-paddings browserNotOk'} id={'uploadPanel'}>
                        <CompatibleBrowserMessage></CompatibleBrowserMessage>
                </div>
                </div>
                );
        }

        const BotNotWelcome = (props) => {
            return (
                <div>
                    <div className={'dx-card responsive-paddings browserNotOk'} id={'uploadPanel'}>
                        <NoBotMessage></NoBotMessage>
                    </div>
                </div>
            );
        }
        return (
            <div className={'content-block'}>
                <LoadPanel
                    shadingColor={'rgba(0,0,0,0.4)'}
                    position={LoadingPos}
                    visible={loggingIn}
                    showIndicator={true}
                    message={"Please wait.."}
                    shading={true}
                    showPane={true}
                />

                <div className={'centrolpayroll'}><img src={eprlogo} alt={'ATC Dropbox'} height='30' className={'eprlogo'} /></div>
                <div className={'epayrolldropbox'}><img src={eprdb} alt={'ATC Dropbox'} height='60' className={'eprdb'} /></div>
                

                {isBrowserOk === 1 ? <BrowserOk {...this.state} ></BrowserOk> :
                    isBrowserOk < 0 ? <BotNotWelcome {...this.state} /> : <BrowserNotOk {...this.state} ></BrowserNotOk> }
            </div>
        );
    }
    /*
    checkSPOInfo() {
        console.log('check SPO web services');
        const requestOption = {
            method: 'GET',
            headers: { 'Content-Type': 'text/plain', 'Accept': 'application/json' }
        }

        return fetch(url + "/getSPAppOnlyToken/", requestOption)
            .then(response=> {
                if (response.ok) {
                    console.log("Success getting token app-only");
                    return response.json();
                } else {
                    console.log("Failed getting token app-only");
                    if (response.status === 401) {

                    }
                    throw 'error';
                }
            }).then(data => {
                this.setState({ loggingIn: false, loggedIn: true });
                return data;
            });
        return true;
    } */

    checkSPOInfo() {
        //console.log('check SPO web services');
        const requestOption = {
            headers: { 'Content-Type': 'text/plain', 'Accept': 'application/json' }
        }
        /*
        let response = await axios.get(url + "/getSPAppOnlyToken/", requestOption)
        let responseOK = response && response.status === 200;
        if (responseOK) {
            console.log("Success getting token app-only");
            this.setState({ loggingIn: false, loggedIn: true });
            return await response.data;
        } else {
            console.log("Failed getting token app-only");
            throw 'error';
        }
        */
        return axios.get(url + "/getSPAppOnlyToken/", requestOption)
            .then(response => {
                //debugger;
                if (response.status === 200) {
                    //console.log("Success getting token app-only");
                    this.setState({ loggingIn: false, loggedIn: true, spoInfo: response.data });
                    return response.data;
                } else {
                    //console.log("Failed getting token app-only");
                    if (response.status === 401) {
                    }
                    ///throw 'error';
                    throw new Error("error");
                }
            });
    } 

    isBrowserOk() {
        if (browser) {
            switch (browser.type) {
                case 'bot':
                    // result is an instanceof BotInfo
                    //console.log(`found ${browser.name} bot`);
                    return -1
                    //break;
                case 'bot-device':
                    // result is an instanceof SearchBotDeviceInfo
                    //console.log(`found ${browser.name} device bot`);
                    return -2
                    //break;
                case 'browser':
                    // result is an instanceof BrowserInfo
                    //console.log(`found ${browser.name} browser, version ${browser.version}`);
                    switch (browser.name) {
                        case 'chrome':
                            return browser.version <= 72 ? 0 : 1;
                            //break;
                        case 'safari':
                            return browser.version <= 12 ? 0 : 1;
                            //break;
                        case 'ios':
                            return browser.version <= 12 ? 0 : 1;
                            //break;
                        case 'firefox':
                            return browser.version <= 68 ? 0 : 1;
                            //break;
                        case 'edge-chromium':
                            return browser.version <= 83 ? 0 : 1;
                            //break;
                        //case 'edge':
                            // return browser.version <= 42 ? 0 : 1;
                            // break;
                        default:
                            return 0;
                            //break;
                    }
                    //break;
                case 'node':
                    // result is an instanceof NodeInfo
                    //console.log(`found node version ${browser.version}`);
                    return -3
                    //break;
                default:
                    return -3;
            }
        }
        return 0; // unknown agent
    }
}

export default uploadfile;