import React, { Component } from 'react';
import ResponsiveBox, {
    Row,
    Col,
    Item,
    Location
} from 'devextreme-react/responsive-box';

import './compatiblebrowsermessage.scss';
import NoBot from "../../media/NoBot.png"

/*
function screen(width) {
    return (width < 700) ? 'sm' : 'lg';
}*/

class compatiblebrowsermessage extends Component {
    /*
    constructor(props) {
        super(props);
    } */
    render() {
        return (
            <ResponsiveBox>
                <Row ratio={1} />
                <Col ratio={1} />
                <Item>
                    <Location screen="xs sm md lg" row={0} col={0} />
                    <div className="cbPanel">
                        <div className={'messageTitle'}>Sorry, No Bot</div> 
                        <div className={'messageWarningText'}><img src={NoBot} alt={'Sorry, No Bot'} className={'noBotIcon'} />
                        </div>
                    </div>
                </Item>
            </ResponsiveBox>

        )
    };
}

export default compatiblebrowsermessage;