import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize("UA-75182977-7"); // put your tracking id here
};
export const GApageView = (page) => {
    ReactGA.pageview(page);
};
export const GAmodalView = (modal) => {
    ReactGA.modalview(modal);
};
export const GAevent = (categoryName, eventName) => {
    ReactGA.event({
        category: categoryName,  // Required
        action: eventName,       // Required
        label: 'Uploader',
        value: 10,
        nonInteraction: false
    });
}
export const GAtiming = (categoryName, variableName, valueNum) => {
    ReactGA.timing({
        category: categoryName,
        variable: variableName,
        value: valueNum
    });
};
export const GAexception = (detail) => {
    ReactGA.exception({ description: detail });
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

