import React from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";

export default class UserPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo: {}
        };
    }
    componentDidMount() {
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');
        //debugger;
        if (userinfo) {
            this.setState({ userinfo: userinfo });
        }
    }

  render() {
      const { menuMode, menuItems } = this.props;
      const { userinfo } = this.state;
      const userName = userinfo ? userinfo.email : 'User Name';
    return (
      <div className={'user-panel'}>
        <div className={'user-info'}>
          <div className={'image-container'}>
            <div className={'user-image'} />
                </div>
                <div className={'user-name'}>{userName}</div>
        </div>

        {menuMode === 'context' && (
          <ContextMenu
            items={menuItems}
            target={'.user-button'}
                    showEvent={'dxclick'}
                    width={'170'}
            position={{ my: 'top center', at: 'bottom center' }}
            cssClass={'user-menu'}
          />
        )}
        {menuMode === 'list' && (
          <List className={'dx-toolbar-menu-action'} items={menuItems} />
        )}
      </div>
    );
  }
}
