import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule, EmailRule, StringLengthRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import { LoadIndicator } from 'devextreme-react/load-indicator'
import './login-form.scss';
//import appInfo from '../../app-info';
import { sendRequest } from '../../_helper/util.js';
import notify from 'devextreme/ui/notify';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";
import { loadReCaptcha } from 'react-recaptcha-google';
import { ReCaptcha } from 'react-recaptcha-google'
//import eprlogo from "../..//media/epr.png"
import eprdb from "../..//media/eprdb.png"
//import sbclogo from "../..//media/sb_cologo-full_color_300.png"
import { initGA, GAevent } from '../../index';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_API_URL + "/api";

export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            email: '',
            message: '',
            emailForgetCode: '',
            recaptchaToken: '',
            loggingin: false,
            loginButtonText:'Enter',

            showForgetCode: false,
            messageForgetCode: '',
            recaptchaTokenForgetCode: '',
            logginginForgetCode: false,
            getCodeButtonText:'Get Code'
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.onLoadRecaptchaForgetCode = this.onLoadRecaptchaForgetCode.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.verifyCallbackForgetCode = this.verifyCallbackForgetCode.bind(this);
    }

    componentDidMount() {
        loadReCaptcha();
        initGA();
        //debugger;
        //https://medium.com/codeep-io/how-to-use-google-recaptcha-with-react-38a5cd535e0d
        if (this.captcha) {
            //debugger;
            this.captcha.reset();
        }
        if (this.captchaForgetCode) {
            //debugger;
            this.captchaForgetCode.reset();
        }

    };
    onLoadRecaptcha() {
        //debugger;
        if (this.captcha) {
            this.captcha.reset();
        }
    }
    onLoadRecaptchaForgetCode() {
        //debugger;
        if (this.captchaForgetCode) {
            this.captchaForgetCode.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        //debugger;
        this.setState({ recaptchaToken: recaptchaToken });
    }
    verifyCallbackForgetCode(recaptchaTokenForgetCode) {
        //debugger;
        this.setState({ recaptchaTokenForgetCode: recaptchaTokenForgetCode });
    }

    render() {
        const { code,
            email,
            message,
            loggingin,
            loginButtonText,
            showForgetCode,
            emailForgetCode,
            messageForgetCode,
            logginginForgetCode,
            getCodeButtonText } = this.state;
        return (
            <div>
                
                {showForgetCode ?
                    <ValidationGroup>
                        <div className={'login-header'}>
                            <div><img src={eprdb} alt="ATC Dropbox" className={'eprdb'} /></div>
                            <div className={'forgetCodeTitle'}>Forgot Code</div>
                            <div className={'forgetCodeInstruction'}></div>
                        </div>
                        <div className={'dx-field'}>
                            <TextBox
                                value={emailForgetCode}
                                onValueChanged={this.emailForgetCodeChanged}
                                placeholder={'Enter Your Email Address'}
                                width={'100%'}
                            >
                                <Validator>
                                    <RequiredRule message={'Email is required'} />
                                    <EmailRule message="Email is invalid" />
                                    <StringLengthRule max={500} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={'dx-field'}>
                            <ReCaptcha
                                ref={(el) => { this.captchaForgetCode = el; }}
                                size="normal"
                                render="explicit"
                                sitekey="6LciI6UZAAAAAA9exR6jkKVZMtDgpP_IiyA653WA"
                                onloadCallback={this.onLoadRecaptchaForgetCode}
                                verifyCallback={this.verifyCallbackForgetCode}

                            />
                        </div>
                        <div className={'dx-field'}>
                            <React.Fragment>
                                <Button
                                    text="Get Code"
                                    type="default"
                                    onClick={this.forgetCode}
                                    disabled={logginginForgetCode}
                                    width={'50%'}
                                    height={'50px'}>
                                    <LoadIndicator className={'button-indicator'} visible={logginginForgetCode} />
                                    <span className="dx-button-text">{getCodeButtonText}</span>
                                </Button>
                                &nbsp;&nbsp;
                                <Button
                                    text="Cancel"
                                    type="normal"
                                    stylingMode="outlined"
                                    onClick={this.cancel}
                                    width={'50%'}
                                    height={'50px'}
                                />
                            </React.Fragment>
                        </div>
                        <div className={'dx-field'}>
                            <div className={'loginmessage'}>{messageForgetCode}</div>
                        </div>

                    </ValidationGroup>
                    :
                    
                    
                    <div id={'loginPanel'}>
                        
                    <ValidationGroup>
                        <div className={'login-header'}>
                                <img src={eprdb} alt="ATC Dropbox" className={'eprdb'} />
                        </div>
                        <div className={'dx-field'}>
                            <TextBox
                                value={code}
                                onValueChanged={this.codeChanged}
                                placeholder={'Enter Code'}
                                width={'100%'}
                            >
                                <Validator>
                                    <RequiredRule message={'Code is required'} />
                                    <StringLengthRule max={50} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={'dx-field'}>
                            <TextBox
                                value={email}
                                onValueChanged={this.emailChanged}
                                placeholder={'Enter Email Address'}
                                width={'100%'}
                            >
                                <Validator>
                                    <RequiredRule message={'Email is required'} />
                                    <EmailRule message="Email is invalid" />
                                    <StringLengthRule max={500} />
                                </Validator>
                            </TextBox>
                        </div>
                        <div className={'dx-field'}>
                                <ReCaptcha
                                    ref={(el) => { this.captcha = el; }}
                                    size="normal"
                                    render="explicit" width={'100%'}
                                    sitekey="6LciI6UZAAAAAA9exR6jkKVZMtDgpP_IiyA653WA"
                                    onloadCallback={this.onLoadRecaptcha}
                                    verifyCallback={this.verifyCallback}
                                />
                        </div>
                            <div className={'dx-field'}>
                                <Button
                                    type={'default'}
                                    text={'ENTER'}
                                    onClick={this.onLoginClick}
                                    width={'100%'}
                                    height={'50px'}
                                    disabled={loggingin}>
                                    <LoadIndicator className={'button-indicator'} visible={loggingin} />
                                    <span className="dx-button-text">{loginButtonText}</span>
                                </Button>
                            
                        </div>
                        <div className={'dx-field'}>
                            <div>
                                    <Button
                                        className={'user-button'}
                                    text="Forgot Code"
                                    type="default"
                                    stylingMode="text"
                                    onClick={this.showForgetcode}>
                               </Button>
                            </div>

                        </div>
                        <div className={'dx-field'}>
                            <div className={'message'}>{message}</div>
                        </div>
                    </ValidationGroup></div>
                }
                
            </div>
        );
    }

    codeChanged = e => {
        this.setState({ code: e.value });
    };

    emailChanged = e => {
        this.setState({ email: e.value });
    };
    emailForgetCodeChanged = e => {
        this.setState({ emailForgetCode: e.value });
    };

    onLoginClick = args => {
        GAevent('LoginPage', 'Login Enter button is clicked')
        if (!args.validationGroup.validate().isValid) {
            return;
        }

        const { code, email, recaptchaToken } = this.state;

        if (recaptchaToken) {
            const myObj = { code: code, email: email };
            const body = JSON.stringify(myObj);
            //debugger;
            this.setState({ loggingin: true, loginButtonText:'Entering...' });
            return sendRequest(url + '/Users/authenticate', 'POST', body).then(myJson => {
                //debugger;
                if (typeof myJson.error !== "undefined") {
                    notify(myJson.error, "ERROR", 3000);
                    this.setState({ loggingin: false, loginButtonText: 'Enter' });
                    args.validationGroup.reset();
                }
                else {

                    const ls = new SecureLS();
                    ls.set('userinfo', myJson);

                    this.props.onLoginClick();
                }
                //args.validationGroup.reset();
                // 2020-06-19 disabled, component is already unmounted
                //this.setState({ loggingin: false, loginButtonText: 'Enter' });
            });
        }
        else {
            notify("Please check the box to indicate that you are not using automated software ", "ERROR", 5000);
            return;
        }

    };

    forgetCode = e => {
        //debugger;
        GAevent('LoginPage', 'Login Get Code button is clicked')
        const { emailForgetCode, recaptchaTokenForgetCode } = this.state;
        if (emailForgetCode === "") {
            this.setState({ messageForgetCode: "Please Enter Email Address." });
        } else {

            if (recaptchaTokenForgetCode) {
                const myObj = { email: emailForgetCode };
                const body = JSON.stringify(myObj);
                this.setState({ logginginForgetCode: true, getCodeButtonText: 'Getting Code...' });
                return sendRequest(url + '/Users/forgetcode', 'POST', body).then(myJson => {
                    //debugger;
                    if (typeof myJson.error !== "undefined") {

                        this.setState({
                            messageForgetCode: myJson.error,
                            logginginForgetCode: false,
                            getCodeButtonText: 'Get Code'
                        });
                    }
                    else {

                        this.setState({
                            message: "User Code has been sent to your email. Thank you.",
                            showForgetCode: false,
                            logginginForgetCode: false,
                            getCodeButtonText: 'Get Code'
                        });


                    }
                    // 2020-06-19 disabled, component is already unmounted
                    //this.setState({ logginginForgetCode: false, getCodeButtonText: 'Get Code' });

                });
            }
            else {
                notify("Please check the box to indicate that you are not using automated software ", "ERROR", 5000);

            }
        }

    }

    showForgetcode = e => {
        this.setState({ showForgetCode: true });
    }

    cancel = e => {
        this.setState({ showForgetCode: false });
    }
}
